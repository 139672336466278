@import "../variables.scss";

.card-steps {
    margin: auto;
    width: auto;
    @media only screen and (max-width: $tablet-size) {
        width: 100%;
    }
    .steps-list {
        text-align: left;
        list-style: none;
        margin: auto !important;
        .list-item {
            margin: 20px 0;
            color: white;

            &.in-progress {
                color: wheat;
            }

            &.completed {
                color: teal;
            }
        }
    }
}