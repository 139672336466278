@import "../variables.scss";

.select-club-tool {


    .ui.table.select-club-table {
        @media only screen and (max-width: $tablet-size) {
            width: 100%;
        }

        overflow-y: scroll;
        height: 300px;
        background: transparent;
        border: 3px solid $main-color;

        .select-club-table-header {
            color: wheat;
            background: transparent;

            tr {
                th {
                    padding: 8px;
                    color: white;
                    background-color: $main-color;
                    border-radius: 0 !important;
                }

                &.search-row{
                    th{
                        padding: 3px;
                    }
                }
                i.icon.question{
                    float: right;
                }
            }
        }

        .select-club-row {

            &.selected,
            &:hover {
                background-color: $main-color;

            }

            color: white;

            .club-icon-column {
                width: 70px;
            }

            .club-icon {
                width: 32px;
                height: 32px;
                margin: auto;
            }

        }
    }
}