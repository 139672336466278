.card {
    background-color: white;
    padding: 10px 20px 11px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #afafaf;
}

.pay-button {
    padding: 0.7rem 2rem;
    width: 100%;
    color: white;
    margin-top: 10px;
    background-color: #556cd6;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:disabled {

        background-color: #afafaf;
        box-shadow: none;
        cursor: default;

        &:hover {
            box-shadow: none;

        }
    }

    &:hover {
        box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);

    }

}