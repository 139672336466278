@import '../_variables.scss';

.card-wrapper {
    @media only screen and (max-width: $mobile-size) {
        transform: scale(0.8);
    }

    .ui.button.edit-button {
        position: absolute;
        border-radius: 4px;
        top: 0;
        left: 50%;
        z-index: 100;
        background-color: $main-color;
        color: white;
        border-radius: 4px;

        &:hover {
            background-color: $highlight2-color;
        }
    }

    .actual-card {

        margin-top: 100px;
        margin-bottom: 100px;
    }

    .card-preview {
        width: 396px;
        height: 550px;
        //644 900
        //277 402
    }

    .card-overlay {
        width: 396px;
        // height: 900px;
        position: absolute;
        top: 225px;

        @media only screen and (max-width: $tablet-size) {
            top: 445px;
        }

        @media only screen and (max-width: $mobile-size) {
            top: 200px;
        }

        z-index: 2;
        display: flex;

        .card-stats-wrapper {
            width: 100%;

            .info-row {
                display: grid;
                grid-gap: 1px;
                grid-template-columns: repeat(2, 1fr);
                height: 185px;
                margin: auto;
                margin-bottom: 10px;
                width: 70%;

                &.editable {
                    width: 87%;
                    border-bottom: 1px solid gray;
                }

                .left-column {
                    margin-top: auto;
                    margin-left: 35px;
                    margin-bottom: 30px;

                    .overall-stat {
                        font-size: 64px;
                        display: block;
                        width: 100%;
                        line-height: normal;

                        &.editable {
                            input {
                                width: 102px;
                            }
                        }
                    }

                    .position-stat {
                        font-size: 36px;
                        display: block;
                        width: 100%;
                        line-height: normal;

                        &.editable {
                            input {
                                width: 102px;
                            }
                        }
                    }
                }

                .right-column {
                    width: 80%;

                    .uploadButton {
                        width: 100px;
                    }
                }

                .image-wrapper {
                    margin-top: auto;
                    height: 65%;
                    border: 1px solid red;
                }
            }

            .stats-row {
                height: 150px;
                display: grid;
                grid-gap: 1px;
                grid-template-columns: repeat(2, 1fr);
                font-size: 24px;
                width: 60%;
                margin: auto;
                // div:nth-child(odd) {
                //     text-align: right;
                // }
                // div:nth-child(even) {
                //     text-align: left;
                // }
            }


            .name-wrapper {
                font-size: 30px;
                height: 45px;
                font-weight: bold;

                .name-field {
                    display: inline;
                    vertical-align: middle;
                    margin: auto;
                    text-align: center;
                }

            }

        }
    }
}