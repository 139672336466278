@import "../variables.scss";

.ui.table.select-card-table {
    @media only screen and (max-width: $tablet-size) {
        width: 100%;
    }
    .select-card-table-header {
        color: wheat;
        background: transparent;

        tr {
            th {
                color: white;
                background-color: $main-color;
                border-radius: 0 !important;
            }
        }
    }
    background: transparent;
    border: 3px solid $main-color;

    tbody{
        td.sub-header{
            hr{
                border-color: $main-color;
            }
        }
    }
    .select-card-row {
        &.selected,
        &:hover {
            background-color: $main-color;

        }
        color: white;

        .card-icon {
            width: 32px;
            height: 32px;
            margin: auto;
        }

    }
}