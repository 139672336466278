@import "../variables.scss";

.stat-kv-pair {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .card-input-field {
        width: 45px;
        font-size: 18px;
        margin-left: 10px;
        text-align: center;
    }

}
.card-stats{
    .info-row{

        .ui.grid.left-column{
            .card-input-field{
                width: 100%;
            }
        }
    }
    .name-wrapper{
        .card-input-field{
            margin:auto;
            width: 80%;
            text-align: center;
        }
    }
}

.column.stats-wrapper {
    &.left {
        border-right: 3px solid gray;
    }
}

.stat-dropdown-wrapper {
    width: 100%;
}