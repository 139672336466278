@import "./_variables.scss";

.App {
  min-width: 100vw;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  font-family: Verdana, open sans, Arial, helvetica neue, Helvetica, sans-serif;
  background: #151515;
  background-image: url(../assets/Background-splash.png);



  //* LAYOUT *//
  .mainLayout {
    display: flex;
    width: 100%;
    min-height: 100vh;
    max-width: 1337px;
  }

  .ui.grid {
    width: 100%;
    // margin: 30px;
  }

  .ui.segment.gl-segment {
    background: rgba(0, 0, 0, 0.8);

    h3 {
      color: $highlight2-color;
    }
    .ui.text.container{
      width: 100%;
      margin: auto !important;
    }
  }

}

.main-content{
  background: transparent;
  width: 100%;
  min-height: 100vh;
  padding: 30px;
}

/* globals - todo: move to semantic css, remove !imporant with specificity */
.margin-0 {
  margin: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}


//app css

.scroll-to-top {
  z-index: 1000;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ui.segment.lastUpdated {
  background: transparent;
  border: none;
  box-shadow: none;
}

/* pace */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: $highlight-color;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}