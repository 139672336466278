@import '../variables';

.customise-card-wrapper {

    .image-editor-header {
        background: rgba(#27311e, 0.7);
        margin: 0;

    }

    .segment.image-editor-wrapper {
        background-color: #11150e;
        border: 1px solid gray;
        margin-top: 0;
        border: 1px solid #27311e;
        border-radius: 0;


        .controls {
            margin-top: 30px;
            .button-wrapper{
                margin-top: 30px;
            }
        }
        .segment.image-uploader{
            background: rgba(#27311e, 0.7);
            border: none;

        }
        .segment.image-editor {
            height: 100%;
            width: 100%;
            background: rgba(#27311e, 0.7);
            border: none;

            .crop-container {
                height: 175px;
                width: 100%;

                .file-upload-area {
                    color: $text-color;
                }
            }

        }
    }
}