@import '../_variables.scss';

ul.card-steps {
    text-align: left;
    color: white;

    li {

        &::before {
            color: wheat;
        }

    }

    &.completed {
        color: $highlight-color;
    }
}

.main-content.create-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .card-tools {
        width: auto;
        margin: auto !important;
        margin: 1rem 0;
        padding: 1em 1em;
        border-radius: 1em;
        border: none;
        font-size: 1rem;
        max-width: 400px;

        @media only screen and (max-width: $tablet-size) {
            width: 100%;
        }
    }

    .button-row {
        width: 100%;
        display: flex;
        justify-content: center;
        .button-wrapper {
            .ui.button {

                &.submit-button,
                &.cancel-button {
                    border-radius: 4px;

                    &:hover {
                        background-color: $highlight2-color;
                    }
                }

                &.submit-button {
                    background-color: $main-color;
                    color: white;
                }

                &.cancel-button {
                    color: white;
                    background-color: $border-color;
                }
            }

            .row {
                .column {

                    &.select-card-column,
                    &.steps-column {
                        margin: auto;

                    }
                }
            }
        }
    }

}

.ui.popup.visible.edit-popup,
.ui.popup.visible.upload-popup {
    color: #979797;
    background: $main-color;
    // box-shadow: 1px 5px rgba($main-color, 0.8);

    &::before {
        background: $main-color;
        border: none;
    }

    .ui.button.upload-photo {
        color: white;
        border: 1px solid white;
        border-radius: 8px;
        background: $main-color;
        cursor: pointer;
    }
}