/* Main */
$main-color: #29331F;
$bg-color: #111;
$highlight-color: #dbd107;
$highlight2-color: #00c0ff;
$text-color: #979797;

$border-color: #7d7d7d;


$mobile-size: 767px;
$tablet-size: 1200px;