@import "../_variables.scss";

.pushable {
    background: $main-color;
    color: $highlight-color;

    .ui.sidebar.menu {

        background: $main-color;

        .item {
            display: flex;
            justify-content: center;
            color: $highlight-color;

        }

    }

    .ui.container {
        background: $main-color;
        color: $highlight-color;

        .item {
            color: $highlight-color;

            &.left {
                color: $highlight-color;
            }

            &.right {
                color: white;
            }
        }
    }
}

.page-header-wrapper {
    width: 100%;
    background: $bg-color;

    .page-header {
        max-width: 1337px;
        margin: auto;
        display: flex;

        .header-menu {
            
            .ui.menu,
            .ui.top.fixed.menu {
                background: $bg-color;

                .ui.container {
                    justify-content: center;
                    width: 1337px;
                    .item {
                        color: $highlight-color !important;
                        border-right: 1px $border-color solid;

                        &.active {
                            border-bottom: 2px white solid;
                        }
                    }
                }
            }
        }
    }
}

.ui.segment.footer-segment {
    background: $bg-color;
    padding: 0;
    margin: 0;
    width: 100%;

    .ui.grid.footer-grid {
        color: white;
        max-width: 1337px;
        width: 100%;
        margin: 0 auto;
        padding: 18px;
        font-size: 12px;
        justify-content: space-around;

        .row {
            padding: 5px;
        }

        @media (max-width: 767px) {
            div.logo-container {
                justify-content: center;
                display: flex;
                text-align: center;
                margin-left: auto;
                margin-bottom: 15px;
                width: 100%;

            }

            div.list-container {
                padding: 0;
            }
        }

        .ui.image {
            width: 85px;
            height: 35px;
            text-align: center;
        }

        .ui.link.list {
            text-align: left;

            .item {
                color: white;
            }

            .item:hover {
                color: $highlight-color;
            }
        }

        hr {
            margin: 10px;
        }

        h5 {
            color: white;
            text-align: center;
        }
    }
}