@import "../variables.scss";

.select-country-tool {

    margin-top: 30px;

    .ui.table.select-country-table {
        @media only screen and (max-width: $tablet-size) {
            width: 100%;
        }

        overflow-y: scroll;
        height: 300px;
        display: block;
        background: transparent;
        border: 3px solid $main-color;

        .select-country-table-header {
            color: wheat;
            background: transparent;

            tr {
                th {
                    padding: 8px;
                    color: white;
                    background-color: $main-color;
                    border-radius: 0 !important;
                }

                &.search-row {
                    th {
                        padding: 3px;
                    }
                }

                i.icon.question {
                    float: right;
                }
            }
        }

        tbody {
            tr.select-country-row {

                &.selected,
                &:hover {
                    background-color: $main-color;

                }

                td {
                    border: 1px solid $main-color;
                    color: white;

                    .country-icon-column {
                        width: 70px;
                    }

                    .country-icon {
                        width: 32px;
                        height: 32px;
                        margin: auto;
                    }
                }
            }
        }
    }
}