@import "../variables.scss";

.ui.search.search-bar {
    .ui.icon.input{
        width: 300px;
        input{
            background: $main-color;
            border: none;
            border-radius: 0;
            color: wheat;
            &:focus{
                border-bottom: 1px solid teal;
            }
        }
        i.icon.search{
            color: white;
        }
    }

    .results {
        overflow-y: scroll;
        height: 175px;

        div.result {

            &:hover{
                background: $main-color;
                .content{
                    .title{
                        color: white;
                    }
                }
            }
        }
    }
}