@import '../variables.scss';


input.card-input-field {
    background: transparent;
    padding: 0;
    border: none;
    border-bottom: 3px solid white;
    color: white;

    &::selection{
        color: white;
        background-color: $main-color;
    }
}